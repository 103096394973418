import React, {
  useEffect,
} from 'react'
import { useRouter } from 'next/router'

import {
  ButterCMSComponent,
} from '../../butter'
import PackageStep from './steps/PackageStep/PackageStep'
import CheckoutStep from './steps/CheckoutStep/CheckoutStep'
import { OrderStep } from '../../pages/order/[...step]'
import useValidateStep from './useValidateStep'
import OrderComponents from './OrderComponents'
import OrderManagement from './OrderManagement/OrderManagement'
import OrderManagementConfirmation from './OrderManagement/OrderManagementConfirmation'
import { useOrderContext } from './OrderContext'

interface Props {
  bundlesData: {
    components: ButterCMSComponent[];
  };
  checkoutData: {
    components: ButterCMSComponent[];
  };
  orderManagementData: {
    components: ButterCMSComponent[];
  };
  step: OrderStep;
  orderManagementPortingConfirmation: {
    components: ButterCMSComponent[];
  };
  orderManagementNewPortingConfirmation: {
    components: ButterCMSComponent[];
  };
  orderConfirmationData: {
    components: ButterCMSComponent[];
  };
  generalSettings: {
    components: ButterCMSComponent[];
  };
}

export interface OrderRedirectInterface {
  error_5000_url: string;
  error_5001_url: string;
  error_5003_url: string;
  default_error_url: string;
  type: string;
}

export default function OrderFlow({
  step, bundlesData, checkoutData, orderManagementData,
  orderManagementPortingConfirmation, orderConfirmationData, orderManagementNewPortingConfirmation, generalSettings,
}: Props) {
  const router = useRouter()
  const {
    options, setOptions,
  } = useOrderContext()

  const {
    broadband,
  } = options

  let stepComponent: ButterCMSComponent | null = null
  const redirectComponent: any = checkoutData.components.find((item: ButterCMSComponent): any => item.type === 'order_status_code_url_redirect')
  const more_info_modal: any = checkoutData.components.find((item: ButterCMSComponent): any => item.type === 'more_info_modal')
  const link_to_modal: any = checkoutData.components.find((item: ButterCMSComponent): any => item.type === 'link_to_modal')
  useEffect(() => {
    const {
      error_5000_url,
      error_5001_url,
      error_5003_url,
      default_error_url,
    }: OrderRedirectInterface = redirectComponent
    setOptions({
      ...options,
      error_5000_url,
      error_5001_url,
      error_5003_url,
      default_error_url,
    })
  }, [redirectComponent])
  const { validateStep } = useValidateStep()

  switch (step) {
    case 'checkout':
    case 'checkout-details':
    case 'checkout-installation':
    case 'checkout-one-touch-switching':
    case 'checkout-payment':
    case 'checkout-order-summary':
      stepComponent = (
        <OrderComponents data={checkoutData}>
          <CheckoutStep router={router} step={step} data={bundlesData} generalSettings={generalSettings} link_to_modal={link_to_modal} more_info_modal={more_info_modal}/>
        </OrderComponents>
      )
      break
    case 'checkout-order-processing':
      stepComponent = (
        <OrderComponents data={checkoutData}>
          <CheckoutStep router={router} step={step} data={bundlesData} generalSettings={generalSettings} link_to_modal={link_to_modal} more_info_modal={more_info_modal}/>
        </OrderComponents>
      )
      break
    case 'checkout-order-confirmation':
      stepComponent = (
        <OrderComponents data={orderConfirmationData}/>
      )
      break
    case 'order-management':
      stepComponent = (
        <OrderComponents data={orderManagementData}>
          <OrderManagement data={orderManagementData}/>
        </OrderComponents>
      )
      break
    case 'order-management-new-number-confirmation':
      stepComponent = (
        <OrderManagementConfirmation data={orderManagementNewPortingConfirmation}/>
      )
      break
    case 'order-management-porting-confirmation':
      stepComponent = (
        <OrderManagementConfirmation data={orderManagementPortingConfirmation}/>
      )
      break
    case 'broadband':
    case 'broadband-bundles':
    case 'addons':
      stepComponent = (
        <PackageStep bundlesData={bundlesData} generalSettings={generalSettings} preselectedPackageId={broadband?.productOfferingId ?? null} step={step}/>
      )
      break
    default:
      stepComponent = (
        <PackageStep bundlesData={bundlesData} generalSettings={generalSettings} preselectedPackageId={broadband?.productOfferingId ?? null} step={step}/>
      )
  }

  useEffect(() => {
    (async () => {
      await validateStep(step)
    })()
  }, [step])

  return (
    <div>
      {stepComponent}
    </div>
  )
}
